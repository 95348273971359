export default {
    methods: {
        setRiskLevelActivity(risk_level) {
            let riskLevelClass = 'badge--primary';

            if (['low'].includes(risk_level)) {
                riskLevelClass = 'badge--success';
            }

            if (['medium', 'warning'].includes(risk_level)) {
                riskLevelClass = 'badge--warning';
            }

            if (['highest', 'danger'].includes(risk_level)) {
                riskLevelClass = 'badge--danger';
            }

            if (['no_information'].includes(risk_level)) {
                riskLevelClass = 'badge--secondary';
            }

            return riskLevelClass;
        },
    },
};
