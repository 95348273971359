<template>
    <span :class="setRiskLevelActivity(risk_level, risk_score)" class="badge rounded-pill px-2 font-normal text-capitalize d-inline-block">
        {{ risk_level.replace(/_/gi, ' ') }} 
        <template v-if="risk_score">
            / {{ risk_score  }}
        </template>
    </span>
</template>
<script>
import riskLevelMixin from '@/mixins/risk-level.mixin';

export default {
    props: {
        risk_level: {
            type: String,
            required: true,
        },

        risk_score: {
            type: Number,
            required: false,
        }
    },
    mixins: [riskLevelMixin],
};
</script>

<style lang="scss" scoped>
.badge {
    border-radius: 6px;
    font-size: 1rem;
    font-weight: 500;
    line-height: 150%;
    padding: 0px 4px;
    white-space: nowrap;

    &--primary {
        background-color: rgba($general-info, 0.2);
        color: $general-info;
    }

    &--secondary {
        background-color: rgba($ecart-secondary-300, 0.2);
        color: $ecart-secondary-600;
    }

    &--success {
        background-color: rgba($general-success, 0.2);
        color: $general-success;
    }

    &--warning {
        background-color: rgba($general-warning, 0.2);
        color: $general-warning;
    }

    &--danger {
        background-color: rgba($general-error, 0.2);
        color: $general-error;
    }
}
</style>